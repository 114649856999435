* {
    box-sizing: border-box;
    margin: 0px;
    font-family: RobotoFont;
}

.nav-desktop {
  position: sticky;
  top: 0px;
  height: 80px;
  width: 100vw;
  z-index: 0;
  color: #202f44;
  backdrop-filter: blur(10px)
}

.nav-desktop_container {
    color: 'red';
}

.nav-desktop__side li .cta {
    width: 120px;
    height: 40px;
}

.nav-desktop__side li:first-child {
    margin-right: 15px;
}

.nav-desktop__side li {
    display: inline-block;
    pointer-events: auto;
}

li {
  list-style-type: none;
}
a, span {
    font-family: NHaas;
    font-size: 12px;
    font-size: var(--span);
    line-height: 1;
    line-height: var(--span-line_height);
    letter-spacing: 0;
    letter-spacing: var(--span-letter_spacing);
}
.cta {
    position: relative;
    display: inline-block;
    overflow: hidden;
    background: whitesmoke;
    border-radius: 60px;
}

.cta_bg {
  transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) scale(0.0003, 0.000281);
  opacity: 0;
  background-color: rgb(14, 64, 192);
}

.cta_base_bg {
  background-color: rgb(0, 69, 255);
}

.cta.blue span {
    color: #000;
}

.nav-desktop__side li a, .nav-desktop__side li span {
    font-size: 25px!important;
}

.cta span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
    display: inline-block;
    font-weight: 500;
    line-height: 1;
    width: 100%;
    text-align: center;
    z-index: 1;
}