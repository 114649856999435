.image-container {
  position: relative;
  width: 500px;
  height: 500px;
  overflow: hidden;
  margin-top:  50px;
  margin-bottom: 50px;
  margin-right: -5.5%;
}

/*.opacity-image {
  width: 100%;
  height: auto;
}*/

.angled-line {
  position: absolute;
  top: 100%; /* Adjust as needed to position the line */
  left: 0;
  width: 110%;
  height: 6px; /* Line thickness */
  background-color: red; /* Line color */
  transform-origin: top left;
  transform: rotate(-80deg);
}

.crop-overlay {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 20%; /* Adjust the width to crop more or less */
  height: 110%; /* Adjust the height if needed */
  background-color: rgb(130, 166, 219); /* Set the color to match your background */
  transform-origin: bot left;
  transform:  rotate(10deg);
  z-index: 1; /* Place the overlay above the image */
  opacity: 0.6;
  display: block;
}

.angled-line-right {
  position: absolute;
  top: 102%; /* Adjust as needed to position the line */
  left: 80%;
  width: 110%;
  height: 6px; /* Line thickness */
  background-color: red; /* Line color */
  transform-origin: top left;
  transform: rotate(-80deg);
}

.crop-overlay-right {
  position: absolute;
  top: -0%;
  left: 90%;
  width: 20%; /* Adjust the width to crop more or less */
  height: 110%; /* Adjust the height if needed */
  background-color: rgb(130, 166, 219); /* Set the color to match your background */
  transform-origin: bot left;
  transform:  rotate(10deg);
  z-index: 1; /* Place the overlay above the image */
  opacity: 0.6;
  display: block
}
